$color-primary: rgb(91, 26, 148) !default;

$color-black: #2f2f2f;
$color-gray: #d8d8d8;
$color-gray-dark: scale-color($color-gray, $lightness: -50%);
$color-gray-light: scale-color($color-gray, $lightness: 50%);
$color-gray-extra-light: scale-color($color-gray, $lightness: 80%);
$color-gray-contrast: #595959; // sufficient for any size text against white background
$color-gray-contrast-title: #757575; // sufficient for large text against white background
$color-white: #fff;

$brand-disabled: $color-gray-light;

/* sizing */
$ec-tile-width: 20rem !default;
$ec-tile-height: 20rem !default;

$card-list-width: 540px;

$lead-bar-height: 8rem;

/* global alert */
$global-alert-height: 10rem;
$share-banner-height: 5rem;

/* nav */
$nav-height: 3.5rem;
$nav-height-lg: 5rem;

/* typography */
$font-family-handwriting: 'Indie Flower', cursive;

@font-face {
  font-family: 'Obviously Regular';
  font-style: normal;
  font-display: auto;
  font-weight: 700;
  font-stretch: normal;
  src: url(assets/fonts/Obviously-Regular.woff2) format('woff2'),
    url(assets/fonts/Obviously-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Obviously Black';
  font-style: normal;
  font-display: auto;
  font-weight: 700;
  font-stretch: normal;
  src: url(assets/fonts/Obviously-Black.woff2) format('woff2'),
    url(assets/fonts/Obviously-Black.woff) format('woff');
}

@font-face {
  font-family: MetamorBit;
  font-style: normal;
  font-weight: 400;
  src: url(https://votesaveamerica.com/wp-content/themes/vsatwentyfour/dist/assets/fonts/MetamorBit_Latin-Regular.woff2)
      format('woff2'),
    url(https://votesaveamerica.com/wp-content/themes/vsatwentyfour/dist/assets/fonts/MetamorBit_Latin-Regular.ttf)
      format('truetype');
}
@font-face {
  font-display: swap;
  font-family: Obviously;
  font-style: normal;
  font-weight: 400;
  src: url(https://votesaveamerica.com/wp-content/themes/vsatwentyfour/dist/assets/fonts/Obviously-Regular.woff2)
      format('woff2'),
    url(https://votesaveamerica.com/wp-content/themes/vsatwentyfour/dist/assets/fonts/Obviously-Regular.woff)
      format('woff');
}
@font-face {
  font-display: swap;
  font-family: Obviously;
  font-style: italic;
  font-weight: 400;
  src: url(https://votesaveamerica.com/wp-content/themes/vsatwentyfour/dist/assets/fonts/Obviously-Italic.woff2)
      format('woff2'),
    url(https://votesaveamerica.com/wp-content/themes/vsatwentyfour/dist/assets/fonts/Obviously-Italic.woff)
      format('woff');
}
