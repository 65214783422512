.printButton {
  background-color: transparent;
  border: none;
  margin-right: 1rem;
  cursor: pointer;

  &:hover svg circle {
    fill: #0d009b;
  }
}
