:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #707070;
  --color-gray: #d8d8d8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 22;
  --color-primary-green: 15;
  --color-primary-blue: 204;

  --color-primary: #160fcc;
  --color-primary-darkened: #0c086d;
  --color-primary-lightened: #554ff2;
  --color-secondary: #5b9872;
  --color-light: #5b9872;

  --brand-danger: #d03620;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #00a551;
  --brand-warning: #160fcc;
  --brand-success-light: #5b9872;

  /* Typography */
  --font-family-base: "Obviously Regular", helvetica, sans-serif;
  --font-family-headings: "Obviously Black", helvetica, sans-serif;
  --font-family-handwriting: "Indie Flower", cursive;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: #eadad2;
  --spinner-color-ring: #ff5d33;

  /* Links */
  --link-color: #ff5d33;
  --link-color--hover: #eadad2;

  /* Global Alert */
  --global-alert-height: 3rem;
  --share-banner-height: 5rem;

  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */

  --color-election-center-bg: #302f2f;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.0277777778em;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  color: #000;
  display: inline-block;
  padding: 16px 30px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease, color 0.2s ease;
  box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-width: 250px;
}
.btn:focus {
  outline: 1px dotted #fff;
  outline-offset: 2px;
}
.btn--blue {
  color: #004e80;
  box-shadow: 8px 8px 0 #004e80;
}
.btn--peach {
  color: #be6951;
  box-shadow: 8px 8px 0 #be6951;
}
.btn--yellow {
  color: #b6985a;
  box-shadow: 8px 8px 0 #b6985a;
}
.btn--purple {
  color: #4b4a7a;
  box-shadow: 8px 8px 0 #4b4a7a;
}
.btn--green {
  color: #646855;
  box-shadow: 8px 8px 0 #646855;
}
.btn--red {
  color: #af3333;
  box-shadow: 8px 8px 0 #af3333;
}
.btn--taupe {
  color: #e5d4d4;
  box-shadow: 8px 8px 0 #e5d4d4;
}
.btn--disabled,
.btn:disabled {
  background-color: #ffc6b3;
  cursor: default;
  pointer-events: none;
  color: #be6951;
  box-shadow: 8px 8px 0 #be6951;
}
.btn:hover {
  transform: translate(3px, 3px);
  box-shadow: 5px 5px 0;
}
.btn:active {
  transform: translate(8px, 8px);
  box-shadow: 0 0 0;
}
.btn--transparent {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #000;
  box-shadow: none;
  color: #000;
  padding: 12px 20px;
}
.btn--transparent:hover {
  background-color: #000;
  color: #fff;
  transform: translate(0);
  box-shadow: none;
}
.btn--transparent:active {
  transform: translate(0);
  box-shadow: none;
}
.btn--transparent.active {
  background-color: #000;
  color: #fff;
}
.btn--black {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  background-color: #000;
  box-shadow: none;
  color: #fff;
}
.btn--black:hover {
  background-color: #000;
  color: #fff;
  transform: translate(0);
  box-shadow: none;
}
.btn--black:active {
  transform: translate(0);
  box-shadow: none;
}
.btn--black.active {
  background-color: #000;
  color: #fff;
}
.textbtn {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.0277777778em;
  text-transform: uppercase;
  margin: 0;
  color: #000;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}
.textbtn:focus {
  outline: 1px dotted #fff;
  outline-offset: 2px;
}
.textbtn:after {
  pointer-events: none;
  content: '';
  background-color: #000;
  bottom: -4px;
  height: 2px;
  left: 0;
  right: 0;
  position: absolute;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.textbtn--small {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
}
@media screen and (min-width: 50em) {
  .textbtn--small {
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: 0.0277777778em;
  }
}
.textbtn--small:after {
  bottom: -2px;
}
.textbtn--small-always {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
}
.textbtn--small-always:after {
  bottom: -2px;
}
.textbtn--white {
  color: #fff;
}
.textbtn--white:after {
  background-color: #fff;
}
.textbtn[target='_blank']:not(.textbtn--no-icon) {
  position: relative;
  padding-right: 24px;
}
.textbtn[target='_blank']:not(.textbtn--no-icon)::before {
  content: '';
  width: 16px;
  height: 16px;
  background-image: url('https://votesaveamerica.com/wp-content/themes/vsa/images/icon-external-link-white.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
}
.textbtn:hover::after {
  transform: scale(1.1);
}
select {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  font-size: 1.6rem;
  line-height: 1;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
  padding: 0 20px;
}
option {
  color: #000;
}
input {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  -webkit-appearance: none;
  background-color: #fff;
  border: 0;
  color: #000;
  padding: 16px 20px;
}
input[type='checkbox'] {
  opacity: 0;
  padding: 1px;
}
input[type='checkbox']:focus + label {
  outline: 1px dotted #fff;
  outline-offset: 2px;
}
input[type='checkbox'] + label {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  margin: 8px auto;
  padding-left: 0px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
input[type='checkbox'] + label:before,
input[type='checkbox'] + label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 0;
  transition: all 0.3s ease;
}
input[type='checkbox'] + label:before {
  border: 2.5px solid #fff;
  content: '';
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0);
}
input[type='checkbox'] + label:hover:before {
  background-color: rgba(255, 255, 255, 0.2);
}
input[type='checkbox']:checked + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  border-left: 2.5px solid #fff;
  border-bottom: 2.5px solid #fff;
  height: 7px;
  width: 11px;
  transform: rotate(-45deg);
}
input[type='radio'] {
  opacity: 0;
  padding: 1px;
}
input[type='radio']:focus + label {
  outline: 1px dotted #fff;
  outline-offset: 2px;
}
input[type='radio'] + label {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 8px auto;
  padding-left: 32px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
input[type='radio'] + label:before,
input[type='radio'] + label:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 15px;
  height: 15px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}
input[type='radio'] + label:before {
  border: 2.5px solid #fff;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 3px #000, inset 0 0 0 17px #000;
}
input[type='radio'] + label:hover::before {
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 3px #000, inset 0 0 0 17px rgba(255, 255, 255, 0.4);
}
input[type='radio']:checked + label:before {
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 17px #fff;
}
:root {
  --link-color: #0b7fc0 !important;
  --link-color--hover: #497a5b !important;
  --brand-danger: #e94b4c !important;
  --color-primary: #160fcc !important;
  --brand-success: #5b9872 !important;
}
.AddressForm__input {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid #000;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-top: none;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.AddressForm__input::-webkit-input-placeholder,
.AddressForm__input::placeholder {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  color: #e0e0e0;
  text-decoration: none;
  font-style: italic;
}
.AddressForm__input:focus {
  background-color: rgba(0, 0, 0, 0.35);
}
.AddressForm__input:focus::-webkit-input-placeholder,
.AddressForm__input:focus::placeholder {
  opacity: 0.8;
}
.AddressForm__submit {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  border: 4px solid #000;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background 0.15s linear;
}
.AddressForm__submit:focus {
  outline: 1px dotted #fff;
  outline-offset: 2px;
}
.AddressForm__submit:hover,
.AddressForm__submit:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #000;
  color: #000;
  text-decoration: none;
}
.AddressForm__submit:hover div,
.AddressForm__submit:focus div {
  text-decoration: none;
}
.AddressForm__submit:hover div:hover,
.AddressForm__submit:hover div:focus,
.AddressForm__submit:focus div:hover,
.AddressForm__submit:focus div:focus {
  text-decoration: none;
}
.AddressForm__submit:disabled {
  opacity: 0.8;
  cursor: default;
  pointer-events: none;
}
button.AddressForm__submit {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.0357142857em;
  text-transform: uppercase;
  margin: 0;
  border: 4px solid #000;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background 0.15s linear;
}
button.AddressForm__submit:focus {
  outline: 1px dotted #fff;
  outline-offset: 2px;
}
button.AddressForm__submit:hover,
button.AddressForm__submit:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #000;
  color: #000;
  text-decoration: none;
}
button.AddressForm__submit:hover div,
button.AddressForm__submit:focus div {
  text-decoration: none;
}
button.AddressForm__submit:hover div:hover,
button.AddressForm__submit:hover div:focus,
button.AddressForm__submit:focus div:hover,
button.AddressForm__submit:focus div:focus {
  text-decoration: none;
}
button.AddressForm__submit:disabled {
  opacity: 0.8;
  cursor: default;
  pointer-events: none;
}
.PlaceField__Hint {
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
}
.PlaceField__dropdown-container {
  background-color: #fff;
  border-radius: 0;
  color: #000;
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  overflow-y: auto;
  border: 0px solid #000;
  top: calc(100% + 3px);
}
.PlaceField__dropdown-footer {
  border-top: 0px solid #000;
  padding-top: 0;
}
.PlaceField__dropdown-footer img {
  height: 10px;
  width: auto;
}
.PlaceField__suggestion:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.1);
}
.PlaceField__suggestion:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.Footer {
  -webkit-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: 0.02778em;
  text-transform: uppercase;
}
.Footer li > a,
.Footer a {
  font-weight: 700;
  color: #fff !important;
  text-decoration: underline;
}
.Footer li > a:hover,
.Footer a:hover {
  color: #fff !important;
}
.ElectionCenterSidebar_infoList__JF9Dh svg {
  margin-top: 2px !important;
}
.Button.Button--sm[href="https://votesaveamerica.com/"]
{
  border-color: #5b9872;
  color: #5b9872;
}
h1,
h2 {
  line-height: 0.92 !important;
  font-weight: 900 !important;
  font-stretch: condensed !important;
  letter-spacing: normal !important;
  text-transform: uppercase !important;
}
.ElectionCenterHeader_header__3NGlM p:first-child {
  font-weight: 900 !important;
  letter-spacing: normal !important;
  text-transform: uppercase !important;
} /*# sourceMappingURL=styles.css.map */
/* Share Scene */
:root {
  --share-image-background: #326272;
  --share-image-text: #e39674;
  --share-image-label-text: #ffffff;
}

/* embed scene */


.Main .HomeScene__Box {
  background-color: #160fcc;
  padding: 32px;
}
h1[class^="AddressWidget_title__"] {
  color: #fff;
  margin-top: 20px;
}
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__email
  .AddressForm__input {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border: none;
  border-radius: 0;
  color: #ffbf10;
  font-family: Obviously, sans-serif;
  font-family: var(--font-family-headings);
  font-size: 1.375rem;
  font-weight: 800;
  height: 70px;
  line-height: 125%;
  padding: 20px 0;
  text-transform: none;
  transition:
    background 0.3s ease,
    color 0.3s ease;
}
@media (min-width: 768px) {
  .Main
    form.AddressForm
    .AddressForm__formGroup.AddressForm__email
    .AddressForm__input {
    font-size: 1.625rem;
  }
}
@media (min-width: 1024px) {
  .Main
    form.AddressForm
    .AddressForm__formGroup.AddressForm__email
    .AddressForm__input {
    font-size: 1.875rem;
  }
}
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__email
  .AddressForm__input:active,
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__email
  .AddressForm__input:focus {
  background-position: 0 100%;
}
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__email
  .AddressForm__input:-webkit-autofill,
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__email
  .AddressForm__input:-webkit-autofill:focus {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border-bottom: 6px solid #f65137;
  transition:
    background-color 0s 600000s,
    color 0s 600000s;
}
.Main form.AddressForm .AddressForm__formGroup {
  margin-bottom: 0;
}
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__FirstName,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__LastName,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__Phone {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border: none;
  border-radius: 0;
  color: #ffbf10;
  font-family: Obviously, sans-serif;
  font-family: var(--font-family-headings);
  font-size: 1.375rem;
  font-weight: 800;
  height: 70px;
  line-height: 125%;
  padding: 20px 0;
  text-transform: none;
  transition:
    background 0.3s ease,
    color 0.3s ease;
}
@media (min-width: 768px) {
  .Main
    form.AddressForm
    .AddressForm__formGroup
    .AddressForm__input.AddressForm__FirstName,
  .Main
    form.AddressForm
    .AddressForm__formGroup
    .AddressForm__input.AddressForm__LastName,
  .Main
    form.AddressForm
    .AddressForm__formGroup
    .AddressForm__input.AddressForm__Phone {
    font-size: 1.625rem;
  }
}
@media (min-width: 1024px) {
  .Main
    form.AddressForm
    .AddressForm__formGroup
    .AddressForm__input.AddressForm__FirstName,
  .Main
    form.AddressForm
    .AddressForm__formGroup
    .AddressForm__input.AddressForm__LastName,
  .Main
    form.AddressForm
    .AddressForm__formGroup
    .AddressForm__input.AddressForm__Phone {
    font-size: 1.875rem;
  }
}
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__FirstName:active,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__FirstName:focus,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__LastName:active,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__LastName:focus,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__Phone:active,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__Phone:focus {
  background-position: 0 100%;
}
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__FirstName:-webkit-autofill,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__FirstName:-webkit-autofill:focus,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__LastName:-webkit-autofill,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__LastName:-webkit-autofill:focus,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__Phone:-webkit-autofill,
.Main
  form.AddressForm
  .AddressForm__formGroup
  .AddressForm__input.AddressForm__Phone:-webkit-autofill:focus {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border-bottom: 6px solid #f65137;
  transition:
    background-color 0s 600000s,
    color 0s 600000s;
}
.Main form.AddressForm .AddressForm__checkbox.AddressForm__label--light {
  align-items: center;
  color: #fff;
  display: flex;
  font-family: Obviously, sans-serif;
  font-size: 0.75rem;
  font-size: 12px !important;
  font-style: italic;
  font-weight: 400;
  font-weight: 400 !important;
  line-height: 155%;
  margin-top: 15px;
  padding: 5px !important;
}
.Main form.AddressForm .AddressForm__checkbox.AddressForm__label--light a {
  text-decoration: underline;
}
.Main
  form.AddressForm
  .AddressForm__checkbox.AddressForm__label--light
  a:hover {
  font-style: normal;
  font-weight: 400;
}
@media (min-width: 768px) {
  .Main form.AddressForm .AddressForm__checkbox.AddressForm__label--light {
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .Main form.AddressForm .AddressForm__checkbox.AddressForm__label--light {
    font-size: 0.875rem;
  }
}
.Main
  form.AddressForm
  .AddressForm__checkbox.AddressForm__label--light
  input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  border: 3px solid #ffbf10;
  border-radius: 0;
  color: #ffbf10;
  display: inline-grid;
  height: 1rem;
  margin: 2px 12px 0 0 !important;
  max-height: 1rem;
  max-width: 1rem;
  opacity: 1;
  place-content: center;
  width: 1rem;
}
.Main
  form.AddressForm
  .AddressForm__checkbox.AddressForm__label--light
  input[type="checkbox"]:before {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23FFC934' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='3' d='M1.723 9.944c1.81 3.225 3.042 4.688 4.852 7.945C9.256 9.842 11.5 6.278 18.223 2'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  bottom: 3px;
  content: "";
  height: 1.375rem;
  left: 5px;
  margin: 0;
  position: relative;
  transform: scale(0);
  transform-origin: bottom left;
  transition: all 0.12s ease-in-out;
  width: 1.375rem;
}
.Main
  form.AddressForm
  .AddressForm__checkbox.AddressForm__label--light
  input[type="checkbox"]:checked:before {
  transform: scale(1);
}
.Main
  form.AddressForm
  .AddressForm__checkbox.AddressForm__label--light
  input[type="checkbox"]:focus {
  outline: 1px solid #ffbf10;
  outline-offset: 2px;
}
.Main
  form.AddressForm
  .AddressForm__checkbox.AddressForm__label--light
  input[type="checkbox"]:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
@media (min-width: 768px) {
  .Main form.AddressForm .AddressForm__checkbox.AddressForm__label--light {
    font-size: 14px !important;
  }
}
@media (min-width: 1024px) {
  .Main form.AddressForm .AddressForm__checkbox.AddressForm__label--light {
    font-size: 14px !important;
  }
}
.Main form.AddressForm small.PlaceField__Hint {
  color: #fff;
  font-family:
    MetamorBit,
    Courier New,
    courier,
    monospace;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  margin-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 768px) {
  .Main form.AddressForm small.PlaceField__Hint {
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .Main form.AddressForm small.PlaceField__Hint {
    font-size: 0.875rem;
  }
}
.Main form.AddressForm .PlaceField__dropdown-container {
  border-left: 6px solid #ffbf10;
  border-right: 6px solid #ffbf10;
}
.Main form.AddressForm .PlaceField__dropdown-footer {
  border-bottom: 6px solid #ffbf10;
  margin-top: -3px;
}
.Main form.AddressForm .PlaceField__suggestion {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
}
.Main form.AddressForm .PlaceField__suggestion span {
  border-bottom: 2px solid #ffbf10;
  display: block;
  padding: 0.625rem 1.25rem;
  width: 90%;
}
.Main form.AddressForm .PlaceField__suggestion:last-of-type span {
  border-bottom: 0;
}
.Main form.AddressForm .AddressForm__formGroup.AddressForm__address {
  grid-column: 1 / span 3;
  margin-bottom: 0;
}
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__address::-webkit-input-placeholder,
.Main
  form.AddressForm
  .AddressForm__formGroup.AddressForm__address::placeholder {
  font-family: var(--font-family-headings);
}
.Main form.AddressForm label.AddressForm__label {
  bottom: -10px;
  color: #fff;
  font-family:
    MetamorBit,
    Courier New,
    courier,
    monospace;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  position: relative;
}
@media (min-width: 768px) {
  .Main form.AddressForm label.AddressForm__label {
    font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  .Main form.AddressForm label.AddressForm__label {
    font-size: 0.875rem;
  }
}
.Main form.AddressForm input.PlaceField__input.AddressForm__input {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border: none;
  border-radius: 0;
  color: #ffbf10;
  font-family: Obviously, sans-serif;
  font-family: var(--font-family-headings);
  font-size: 1.375rem;
  font-weight: 800;
  height: 70px;
  line-height: 125%;
  padding: 20px 0;
  text-transform: none;
  transition:
    background 0.3s ease,
    color 0.3s ease;
}
@media (min-width: 768px) {
  .Main form.AddressForm input.PlaceField__input.AddressForm__input {
    font-size: 1.625rem;
  }
}
@media (min-width: 1024px) {
  .Main form.AddressForm input.PlaceField__input.AddressForm__input {
    font-size: 1.875rem;
  }
}
.Main form.AddressForm input.PlaceField__input.AddressForm__input:active,
.Main form.AddressForm input.PlaceField__input.AddressForm__input:focus {
  background-position: 0 100%;
}
.Main
  form.AddressForm
  input.PlaceField__input.AddressForm__input:-webkit-autofill,
.Main
  form.AddressForm
  input.PlaceField__input.AddressForm__input:-webkit-autofill:focus {
  background: linear-gradient(270deg, #ffbf10 50%, #f65137 0) 100%;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 200% 6px;
  border-bottom: 6px solid #f65137;
  transition:
    background-color 0s 600000s,
    color 0s 600000s;
}
.Main form.AddressForm button.AddressForm__submit {
  background-color: #f65137;
  border: 5px solid #f65137;
  border-radius: 0;
  clip-path: polygon(
    0 8px,
    8px 8px,
    8px 8px,
    8px 8px,
    8px 0,
    calc(100% - 8px) 0,
    calc(100% - 8px) 8px,
    calc(100% - 8px) 8px,
    calc(100% - 8px) 8px,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    8px calc(100% - 8px),
    8px calc(100% - 8px),
    8px calc(100% - 8px),
    0 calc(100% - 8px)
  );
  color: #fff;
  font-family: var(--font-family-headings);
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 100%;
  margin-top: 25px;
  min-width: 125px;
  padding: 17px 21px 20px;
  text-align: center;
  text-transform: none;
  transition:
    background 0.3s ease-in-out,
    border 0.3s ease-in-out;
  width: fit-content;
}
.Main form.AddressForm button.AddressForm__submit:hover {
  background-color: #d03620;
  border-color: #d03620;
  font-style: italic;
  opacity: 1;
}
.Main form.AddressForm .PlaceField__dropdown-container {
  color: #000;
  font-family: Obviously, sans-serif;
  font-family: var(--font-family-base);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 155%;
}
@media (min-width: 768px) {
  .Main form.AddressForm .PlaceField__dropdown-container {
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .Main form.AddressForm .PlaceField__dropdown-container {
    font-size: 1rem;
  }
}
.Main
  form.AddressForm
  .PlaceField__dropdown-container
  .PlaceField__suggestion:nth-child(2n) {
  background-color: #fff;
}
.Main
  form.AddressForm
  .PlaceField__dropdown-container
  .PlaceField__suggestion:hover {
  background-color: #ffbf10;
  color: #000;
}
/* end embed scene */
/*# sourceMappingURL=ballotReady.css.map*/